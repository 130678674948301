import { extendTheme } from '@chakra-ui/react'

const colors = {
	brand: {
		50: '#ddf8ff',
		100: '#b6e5f9',
		200: '#8cd3f0',
		300: '#61c0e8',
		400: '#38aee1',
		500: '#2CA9DF',
		600: '#11739c',
		700: '#035271',
		800: '#003246',
		900: '#00121d',
	},
	ocean: {
		50: '#e5e9ff',
		100: '#b5c0ff',
		200: '#838ffc',
		300: '#515cf9',
		400: '#2127f7',
		500: '#0b08dd',
		600: '#0d06ad',
		700: '#0b037c',
		800: '#02034c',
		900: '#00031e',
	},
	octopus: {
		50: '#efeffe',
		100: '#d2d1e5',
		200: '#b4b2cd',
		300: '#9693b8',
		400: '#7875a3',
		500: '#5f5b89',
		600: '#4a476c',
		700: '#35334e',
		800: '#201e31',
		900: '#0c0916',
	}
}

const fonts = {
	heading: 'Nunito Sans, system-ui, sans-serif',
	body: 'Nunito Sans, system-ui, sans-serif',
}

const fontWeights = {
	normal: 400,
	semibold: 600,
	bold: 700,
	extrabold: 800
}

const styles = {
	global: {
		"body, html": {
			color: 'ocean.700',
			height: 'full'
		},
		button: {
			color: 'brand.700'
		},
		a: {
			color: 'brand.500',
			_hover: {
				textDecoration: 'underline',
			}
		}
	}
}

const theme = extendTheme({colors, fonts, fontWeights, styles})

export default theme