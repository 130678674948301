// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-contentful-template-about-js": () => import("./../../../src/templates/ContentfulTemplateAbout.js" /* webpackChunkName: "component---src-templates-contentful-template-about-js" */),
  "component---src-templates-contentful-template-contact-js": () => import("./../../../src/templates/ContentfulTemplateContact.js" /* webpackChunkName: "component---src-templates-contentful-template-contact-js" */),
  "component---src-templates-contentful-template-service-js": () => import("./../../../src/templates/ContentfulTemplateService.js" /* webpackChunkName: "component---src-templates-contentful-template-service-js" */)
}

